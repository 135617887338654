import { Camp } from '../components/SimSumHelpers'
import Layout from '../layouts/layout'
import Masonry from 'react-masonry-css'
import React from 'react'
import ribbon from '../images/ribbon.svg'
import slugify from 'slugify'

class CampDetails extends React.PureComponent {
  render () {
    const camp = this.props.camp

    const accountTier = camp.organization.accountTier

    const cardClass = `camp card shadow-sm ss-${ accountTier }`

    const img = (camp.image) ? (accountTier === 4) ? (<><div className="ribbon"><img src={ ribbon } /></div><img className="img-fluid rounded mb-3 shadow-sm border" src={ camp.image } alt="camp detail imagery" /></>) : (accountTier > 1) ? (<><img className="img-fluid rounded mb-3 shadow-sm border" src={ camp.image } alt="camp detail imagery" /></>) : null : null

    const link = `/camp/${ camp.id }/${ slugify([camp.organization.name, camp.name].join(' '), { replacement: '-', remove: /[^A-Za-z0-9 ]/g, lower: true }) }/`

    return (
        <div className={cardClass} key={camp.id} id={camp.id}>
          <a href={ link }>
            <div className="card-body">
              { img }
              <h4 className="">{camp.name}</h4>
              <h6>{ camp.organization.name }</h6>
              <div>{ camp.summary }</div>
            </div>
            <div className="card-footer d-lg-block">
              <div className="small font-italic summary">{ camp.snapshot() }</div>
            </div>
          </a>
        </div>
    )
  }
}

class PageContent extends React.PureComponent {
  render () {
    const curatedList = this.props.pageContext.curatedList

    return (
      <Layout location={this.props.location}>

        <div className="container-fluid yellow">
          <div className="container py-5">
            <div className="row">
              <div className="col-12">
                <h2>{curatedList.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: curatedList.description ? curatedList.description.childContentfulRichText.html : null }} />
              </div>
              <div className="col-12">
                <Masonry breakpointCols={{ default: 4, 992: 3, 768: 2, 576: 1 }} className="simsum-masonry-grid" columnClassName="simsum-masonry-grid-column">
                  {curatedList.camps.map((campData, index) => (<CampDetails camp={ new Camp(campData) } key={index} />))}
                </Masonry>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default PageContent
